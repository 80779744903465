import {ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {createEventHandler} from '@wix/tpa-settings'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {
  createMembersAreaWidgetPluginService,
  createWidgetPluginExports,
  routerDataServiceFactory,
} from '@wix/members-area-widget-plugin-lib/viewer'
import {SettingsEvents} from './constants'
import {fetchInitialState} from './controller-utils'
import {registerWidgetPluginHostListeners} from './controller-listeners'

export const createMembersPageController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerParams.controllerConfig.config.publicData.COMPONENT || {},
  )
  const eventsMembersAreaV2Enabled = controllerParams.flowAPI.experiments.enabled(ExperimentNames.EventsMembersAreaV2)
  let widgetPluginService = null

  if (eventsMembersAreaV2Enabled) {
    widgetPluginService = createMembersAreaWidgetPluginService()
  }

  const initialise = async () => {
    const routerDataService = eventsMembersAreaV2Enabled
      ? routerDataServiceFactory(
          widgetPluginService,
          controllerParams.controllerConfig.wixCodeApi.user,
          controllerParams.controllerConfig.wixCodeApi.window,
        )
      : null

    await fetchInitialState({controllerParams, componentEventHandler, eventsMembersAreaV2Enabled, routerDataService})

    if (eventsMembersAreaV2Enabled) {
      registerWidgetPluginHostListeners({
        widgetPluginService,
        routerDataService,
        controllerParams,
        componentEventHandler,
        eventsMembersAreaV2Enabled,
      })
    }
  }

  return {
    pageReady: async () => {
      const isRendered = widgetPluginService?.getIsRendered() ?? true

      if (eventsMembersAreaV2Enabled && !isRendered) {
        return
      }

      return initialise()
    },
    updateConfig: (_, newConfig) => {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {})
    },
    exports: () =>
      eventsMembersAreaV2Enabled ? createWidgetPluginExports(widgetPluginService, initialise) : undefined,
  }
}

export default createMembersPageController
